import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_provider_step = _resolveComponent("select-provider-step")!
  const _component_credentials_step = _resolveComponent("credentials-step")!
  const _component_link_numbers_step = _resolveComponent("link-numbers-step")!
  const _component_flow_step_list = _resolveComponent("flow-step-list")!

  return (_openBlock(), _createBlock(_component_flow_step_list, {
    "onUpdate:currentStep": _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentStep = $event))
  }, {
    default: _withCtx(({ currentStep, onStepChange }) => [
      _createVNode(_component_select_provider_step, {
        step: 1,
        "current-step": currentStep,
        onStepChange: onStepChange
      }, null, 8, ["current-step", "onStepChange"]),
      _createVNode(_component_credentials_step, {
        step: 2,
        "current-step": currentStep,
        onStepChange: onStepChange
      }, null, 8, ["current-step", "onStepChange"]),
      _createVNode(_component_link_numbers_step, {
        step: 3,
        "current-step": currentStep,
        onStepChange: onStepChange
      }, null, 8, ["current-step", "onStepChange"])
    ]),
    _: 1
  }))
}