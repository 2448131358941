
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmButton from '@/components/shared/TmButton.vue'
import LinkNumbersTable from '@/components/pages/chats/settings/channels/flow/linkNumbers/LinkNumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { LinkNumberType } from '@/definitions/services/byoc/types'
import { NumbersMock } from '@/definitions/services/byoc/data'
import { useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'

export default defineComponent({
  components: {
    TmButton,
    FlowStep,
    LinkNumbersTable,
  },
  setup() {
    const router = useRouter()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Number', value: 'number-slot' },
      { text: 'Label in Textmagic', value: 'label-slot' },
      { text: 'Voice', value: 'voice-slot', width: '80px' },
      { text: 'SMS', value: 'sms-slot', width: '80px' },
      { text: 'MMS', value: 'mms-slot', width: '80px' },
    ])

    const tableItems = ref<LinkNumberType[]>(NumbersMock)
    const selected = ref<LinkNumberType[]>([])
    const isLoading = ref(false)

    const handleClick = () => {
      isLoading.value = true
      setTimeout(() => {
        router.push({ name: 'base.chats.settings.channels' })
        notify({
          text: 'You have successfully connected 2 virtual numbers. You can now start sending and receiving messages using these numbers.',
          type: 'success',
        })
      }, 2000)
    }

    return {
      tableHeaders,
      selected,
      tableItems,
      isLoading,
      handleClick,
    }
  },
})
