
import { defineComponent, ref } from 'vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import SelectProviderStep from '@/components/pages/chats/settings/channels/flow/SelectProviderStep.vue'
import CredentialsStep from '@/components/pages/chats/settings/channels/flow/CredentialsStep.vue'
import LinkNumbersStep from '@/components/pages/chats/settings/channels/flow/LinkNumbersStep.vue'

export default defineComponent({
  components: {
    FlowStepList,
    LinkNumbersStep,
    SelectProviderStep,
    CredentialsStep,
  },
  setup() {
    const currentStep = ref(1)

    return {
      currentStep,
    }
  },
})
