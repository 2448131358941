
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { PropType } from 'vue'
import { useModals } from '@/compositions/modals'
import type { TilesType } from '@/definitions/chats/settings/channels/flow/types'

export default defineComponent({
  components: {
    TmStatus,
    TmButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<TilesType>,
    },
    tiles: {
      type: Object as PropType<TilesType>,
    },
    provider: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const { openModal } = useModals()

    return {
      openModal,
    }
  },
})
