
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmButton from '@/components/shared/TmButton.vue'
import SelectTilesList from '@/components/pages/chats/settings/channels/flow/lists/SelectTilesList.vue'
import type { TilesType } from '@/definitions/chats/settings/channels/flow/types'

export default defineComponent({
  components: {
    TmButton,
    FlowStep,
    SelectTilesList,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['step-change'],
  setup() {
    const provider = ref()
    const providers: TilesType[] = [
      {
        title: 'Twilio',
        image: require('@/assets/images/byoc/short-logos/twilio.svg'),
        text: 'Connect using Twilio account SID and auth token.',
        comingSoon: false,
      },
      {
        title: 'Sinch',
        image: require('@/assets/images/byoc/short-logos/sinch.png'),
        text: 'Connect using Sinch account SID and auth token.',
        comingSoon: false,
      },
      {
        title: 'Vonage',
        image: require('@/assets/images/byoc/short-logos/vonage.png'),
        text: 'Connect using Vonage account SID and auth token.',
        comingSoon: false,
      },
      {
        title: 'MessageBird',
        image: require('@/assets/images/byoc/short-logos/messagebird.svg'),
        text: 'Connect using MessageBird account SID and auth token.',
        comingSoon: true,
      },
      {
        title: 'Amazon SNS',
        image: require('@/assets/images/byoc/short-logos/aws.png'),
        text: 'Connect using Amazon account SID and auth token.',
        comingSoon: true,
      },
      {
        title: 'Bandwidth',
        image: require('@/assets/images/byoc/short-logos/bandwidth.png'),
        text: 'Connect using Bandwidth account SID and auth token.',
        comingSoon: true,
      },
      {
        title: 'Telnyx',
        image: require('@/assets/images/byoc/short-logos/telnyx.png'),
        text: 'Connect using Telnyx account SID and auth token.',
        comingSoon: true,
      },
      {
        title: 'Plivo',
        image: require('@/assets/images/byoc/short-logos/plivo.png'),
        text: 'Connect using Plivo account SID and auth token.',
        comingSoon: true,
      },
    ]
    return {
      provider,
      providers,
    }
  },
})
