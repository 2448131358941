
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { Form as Validation } from 'vee-validate'

export default defineComponent({
  components: {
    TmButton,
    TmAlert,
    TmFormLine,
    FlowStep,
    Validation,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['step-change'],
  setup(props, { emit }) {
    const accountSID = ref()
    const authToken = ref()
    const providerLabel = ref()
    const alertVisible = ref(false)
    const form = ref()
    const counter = ref(0)
    const isLoading = ref(false)

    const validateForm = () => {
      form.value.$el.dispatchEvent(new Event('submit', { cancelable: true }))
    }

    const handleClick = () => {
      isLoading.value = true

      setTimeout(() => {
        if (counter.value > 0) {
          emit('step-change', props.step + 1)
          alertVisible.value = false
        } else {
          counter.value++
          alertVisible.value = true
        }

        isLoading.value = false
      }, 1500)
    }

    return {
      handleClick,
      alertVisible,
      accountSID,
      authToken,
      providerLabel,
      validateForm,
      form,
      isLoading,
    }
  },
})
