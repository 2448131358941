
import { defineComponent } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import ByocFlow from '@/components/pages/chats/settings/channels/flow/ByocFlow.vue'

export default defineComponent({
  name: 'ChatByocFlow',
  components: {
    TmButton,
    DetailsHero,
    PageBlock,
    ByocFlow,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', name: 'base.chats.main' },
      { label: 'Inbox settings', name: 'base.chats.settings' },
      { label: 'SMS, MMS and calls', name: 'base.chats.settings.channels' },
      { label: 'Set up a new CPaaS provider' },
    ]

    return {
      breadcrumbs,
    }
  },
})
